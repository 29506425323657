import { Pagination } from '@dx-ui/osc-pagination';
import { useRef, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';

import { useMetrics, useTrackSearchPricing, useWrappedRouter, useBrandDetails } from '../../hooks';
import type { HotelType } from '../../providers/app-provider/app-provider.types';
import { ListViewBrandBanner } from './list-view-brand-banner';
import { MAX_PROPERTIES_PER_LIST_VIEW_PAGE, env } from '../../constants';
import { AgeBasedModal } from '../../components/age-based-modal';
import type { GoogleCoordinate } from '../../utils';
import { getLengthOfStay } from '../../utils';
import { setFocusOnFirstListViewCard } from './list-view-utils/list-view.utils';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { useConfigRule } from '@dx-ui/framework-config-rule-provider';
import useShopMultiPropAvail from '../../hooks/use-shop-multi-prop-avail/use-shop-multi-prop-avail';
import { getInfoBannerURLWithBrandRemoved } from './list-view-utils/list-view-brand-banner.utils';
import { useGetVisibleInBoundHotels } from '../../hooks/use-get-visible-in-bound-hotels';
import { InfoMessageBanner } from './info-message-banner/info-message-banner';
import { Link } from '@dx-ui/osc-link';
import Icon from '@dx-ui/osc-icon';
import HotelCards from '../hotel-card/hotel-cards';
import HotelCardSkeleton from '../hotel-card/hotel-card-skeleton';
import cx from 'classnames';

type ListViewProps = {
  isConductricsLoaded?: boolean;
  brandName?: string;
  brandCode?: string;
  centerCoordinate: GoogleCoordinate | null;
  isHSODataLoading?: boolean;
  onPageChanged?: (paginationIndex: number, pageSize: number) => void;
  sortedHotels: HotelType[];
};
type IListViewLoadingShimmer = {
  isListViewOnly?: boolean;
  showImages?: boolean;
  cardsClassName?: string;
};

/*
 * List View Loading / Shimmer
 * Displays if hotel data is being fetched from backend
 */
const ListViewLoadingShimmer = ({
  isListViewOnly,
  showImages,
  cardsClassName,
}: IListViewLoadingShimmer) => {
  let cnt = 0;
  return (
    <ul className={cx(cardsClassName)}>
      {Array.from({ length: 6 }, () => (
        <HotelCardSkeleton key={cnt++} isListViewOnly={isListViewOnly} showImages={showImages} />
      ))}
    </ul>
  );
};

export const ListView = ({
  isConductricsLoaded,
  brandName,
  brandCode,
  centerCoordinate,
  isHSODataLoading,
  onPageChanged,
  sortedHotels,
}: ListViewProps) => {
  const { hasConnectingRooms, showHotelImages, paginationIndex, selectedCurrency, pageType } =
    useAppState();
  const listRef = useRef<HTMLUListElement | null>(null);
  const { t } = useTranslation(['locations-page', 'list-view', 'list-view-brand-banner']);
  const metrics = useMetrics();
  const dispatch = useAppDispatch();

  const [isAgeBasedModalOpen, setIsAgeBasedModalOpen] = useState(false);
  const ulContainerRef = useRef<HTMLDivElement | null>(null);

  const { taggedConfig } = useConfigRule();
  // if show hotel images toggle is disabled always show images. Otherwise show images depending on toggle state
  const showImages = !taggedConfig?.config.showListViewHotelImagesToggle || !!showHotelImages;
  const isListViewOnly = !taggedConfig?.config['showMap'];

  const {
    safeQueryParams: queryParameters,
    router: { asPath, locale },
  } = useWrappedRouter();
  const { canonicalSlug, altSlugs, isHotelBrand } = useBrandDetails({ brandCode });

  const currentPageVisibleHotels = sortedHotels.slice(
    paginationIndex * MAX_PROPERTIES_PER_LIST_VIEW_PAGE,
    (paginationIndex + 1) * MAX_PROPERTIES_PER_LIST_VIEW_PAGE
  );
  const totalPages = Math.ceil(sortedHotels.length / MAX_PROPERTIES_PER_LIST_VIEW_PAGE);

  //guarantee we always fetch hotels in list view even if >150. Disable query if on locations page
  const { hashedData: pricing } = useShopMultiPropAvail({
    ctyhocns: currentPageVisibleHotels.map((hotel) => hotel.ctyhocn),
    mpaConstraints: true,
  });

  const showAmountAfterTax = useMemo(() => {
    return asPath.includes('/locations/')
      ? sortedHotels?.some((hotel) => !!hotel?.leadRate?.lowest?.cmaTotalPriceIndicator)
      : sortedHotels.some((hotel) =>
          Boolean(pricing[hotel.ctyhocn]?.summary?.lowest?.cmaTotalPriceIndicator)
        );
  }, [asPath, pricing, sortedHotels]);

  useTrackSearchPricing({
    isDateSearch: true,
    hotels: currentPageVisibleHotels,
    pricing,
    datesFlex: queryParameters?.datesFlex,
    selectedCurrency: selectedCurrency || '',
    lengthOfStay: getLengthOfStay(queryParameters?.departureDate, queryParameters?.arrivalDate),
  });

  const [notification] =
    currentPageVisibleHotels
      .map((hotel) => {
        const hotelPricing = pricing[hotel.ctyhocn];
        if (pricing && !hotelPricing) return null;
        const [notification] = (pricing && hotelPricing && hotelPricing.notifications) || [];
        return notification && notification.subType === 'sale' ? notification : null;
      })
      .filter(Boolean) || [];

  const { brandedVisibleHotels, otherVisibleHotels } = useGetVisibleInBoundHotels({
    currentPageVisibleHotels,
    brandCode,
    brandName,
  });

  const shouldDisplayBrandBannerInCurrentPage = () => {
    const atLeastOneHotelNotBranded = otherVisibleHotels?.length > 0;
    const displayAtEndOfBrandPageResults = pageType.isBrand && paginationIndex === totalPages - 1;

    switch (true) {
      case displayAtEndOfBrandPageResults:
        return true;
      case pageType.isGlobal && atLeastOneHotelNotBranded:
        return true;
      default:
        return false;
    }
  };
  //call onPageChange if it exists and reset focus on first card if paginationIndex changes
  useEffect(() => {
    if (onPageChanged) onPageChanged(paginationIndex, MAX_PROPERTIES_PER_LIST_VIEW_PAGE);
  }, [onPageChanged, paginationIndex]);

  //reset focus on first card in list any time pagination changes
  useEffect(() => {
    if (paginationIndex > 0) setFocusOnFirstListViewCard(ulContainerRef);
  }, [paginationIndex]);

  const handlePagination = async (newPageIndex: number) => {
    dispatch({ type: 'SET_PAGINATION_INDEX', payload: newPageIndex });
    await metrics.trackPaginationClick({
      paginationNumber: `${newPageIndex + 1}`,
      actionType: !queryParameters ? 'categoryPagination' : 'searchAction',
    });
  };
  useEffect(() => {
    // setting setPaginationData separately for each flow (search & locations)
    // since search hotels will not load till conductrics is resolved
    if (isConductricsLoaded && totalPages > 0) {
      // due to string character limit constraints with Adobe,
      // we still want only first page results in the propertyResultList.
      // but propertyResults should reflect the total number of hotels returned.
      metrics.setPaginationData({
        totalPages: `${totalPages}`,
        propertyResultList: otherVisibleHotels.map((hotel) => hotel.ctyhocn).join(','),
        propertyResults: otherVisibleHotels.length,
        brandedHotels: brandedVisibleHotels
          .filter((hotel) => hotel.brandCode === brandCode)
          .map((hotel) => hotel.ctyhocn),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConductricsLoaded]);

  // if the current page is beyond the last page, set it to the last page
  const lastPageIndex = Math.max(totalPages - 1, 0); // go no lower than the first page
  if (paginationIndex > lastPageIndex && dispatch) {
    dispatch({ type: 'SET_PAGINATION_INDEX', payload: lastPageIndex });
  }
  const showHotelCardImage = !!pageType.isDreams || !!showImages;
  const classname = {
    outerContainerClassName: 'p-2 mb-2 flex items-center bg-bg-quarternary',
    textClassName: 'text-sm font-semibold ml-4 leading-none',
    cardsClassName:
      'grid-cols-1 mb-4 grid justify-center gap-2 lg:grid-cols-2 lg:w-[694px] xl:w-[888px]',
  };
  const hotelPlaceId = queryParameters?.placeId?.includes('dx-hotel')
    ? queryParameters?.placeId?.split('::')[1]?.toUpperCase()
    : null;
  const queryParam = queryParameters?.query?.toUpperCase();

  const linkWithoutBrandSlug = pageType.isBrand ? (
    <Link
      url={getInfoBannerURLWithBrandRemoved({
        baseUrl: env.OHW_BASE_URL,
        path: asPath,
        locale,
        canonicalSlug,
        altSlugs,
        brandCode,
      })}
      className="px-2 text-sm font-normal"
    >
      {t('list-view-brand-banner:exploreOtherHotelsLink')}
    </Link>
  ) : null;
  // Shimmer / Loading state for List View
  if (isHSODataLoading && sortedHotels?.length === 0)
    return (
      <ListViewLoadingShimmer
        isListViewOnly={isListViewOnly}
        showImages={showHotelCardImage}
        cardsClassName={classname.cardsClassName}
      />
    );

  // Returns Message when zero hotels are found to adjust search / map
  if (!isHSODataLoading && sortedHotels?.length === 0) {
    const noHotelsFoundBannerMessage = isHotelBrand
      ? t('list-view-brand-banner:couldNotFindHotelsInSearchArea', { brandName })
      : t('notFoundMessage');
    return (
      <InfoMessageBanner message={noHotelsFoundBannerMessage}>
        {linkWithoutBrandSlug}
      </InfoMessageBanner>
    );
  }

  return (
    <div ref={ulContainerRef}>
      {showAmountAfterTax ? <p className="block pb-2 ">{t('list-view:cmaPriceMessage')}</p> : null}
      {hasConnectingRooms && <InfoMessageBanner message={t('list-view:ccrPricingMessage')} />}
      {notification && (
        <div className={classname.outerContainerClassName}>
          <Icon name="info-circle" variant="regular" size="sm" />
          <p className={classname.textClassName}>{notification.text}</p>
        </div>
      )}
      {/* Child based pricing modal when at least one property in map requires child ages but arent specified by customer. but TBD about this location in code (at least no longer in hotel card)*/}
      <AgeBasedModal
        isAgeBasedModalOpen={isAgeBasedModalOpen}
        pricing={pricing}
        setIsAgeBasedModalOpen={setIsAgeBasedModalOpen}
      />

      {Boolean(currentPageVisibleHotels?.length) ? (
        <>
          {/* show property sold out info banner if specific searched property sold out */}
          {(hotelPlaceId && pricing[hotelPlaceId]?.statusCode === 1740) ||
          (queryParam && pricing[queryParam]?.statusCode === 1740) ? (
            <InfoMessageBanner message={t('list-view:selectedHotelSoldOut')} />
          ) : null}
          <HotelCards
            isAgeBasedModalOpen={isAgeBasedModalOpen}
            aria-label={t('list-view:hotelSearchResults')}
            centerCoordinate={centerCoordinate}
            data-testid="hotelsList"
            hotels={currentPageVisibleHotels || []}
            isListViewOnly={isListViewOnly}
            isLoadingState={isHSODataLoading}
            pricing={pricing}
            ref={listRef}
            showImages={showHotelCardImage}
            cardsClassName={classname.cardsClassName}
          />
        </>
      ) : null}
      <ListViewBrandBanner
        brandCode={brandCode}
        isDisplayBrandBanner={shouldDisplayBrandBannerInCurrentPage()}
        isPageBrandFilterEnabled={pageType.isPageBrandFilterEnabled}
        numBrandedHotels={brandedVisibleHotels?.length}
        isEndOfResults={!isHSODataLoading && paginationIndex === lastPageIndex}
      />

      <div className="border-border border-y py-4">
        <Pagination
          current={paginationIndex}
          onNextClick={() => handlePagination(paginationIndex + 1)}
          onPreviousClick={() => handlePagination(paginationIndex - 1)}
          total={totalPages}
        />
      </div>
    </div>
  );
};
