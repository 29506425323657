import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { MAX_PROPERTIES_PER_LIST_VIEW_PAGE } from '../../constants';

export type HotelCounterProps = {
  count: number;
  currentPageIndex: number;
  customMessage?: string;
  customSRMessage?: string;
  isLoading: boolean;
  isListVisible: boolean;
};

export const HotelCounter = ({
  count,
  currentPageIndex,
  isLoading,
  customMessage,
  customSRMessage,
  isListVisible,
}: HotelCounterProps) => {
  const pageStart = currentPageIndex * MAX_PROPERTIES_PER_LIST_VIEW_PAGE + 1;
  const pageEnd =
    pageStart + MAX_PROPERTIES_PER_LIST_VIEW_PAGE - 1 < count
      ? pageStart + MAX_PROPERTIES_PER_LIST_VIEW_PAGE - 1
      : count;
  const { t } = useTranslation('hotel-counter-text');

  const message = customMessage
    ? customMessage
    : count > MAX_PROPERTIES_PER_LIST_VIEW_PAGE && isListVisible
    ? t('hotelShowingWithPages', { count, pageStart, pageEnd })
    : t('hotelShowingWithCount', { count });

  const srMessage = customSRMessage
    ? customSRMessage
    : count === 0
    ? t('notFoundMessage')
    : message;
  const [srCounter, setSrCounter] = useState(1);
  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (isLoading) {
      interval = setInterval(() => setSrCounter(srCounter + 1), 3000);
    } else {
      setSrCounter(0);
    }
    return () => clearInterval(interval);
  }, [isLoading, srCounter]);

  return isLoading ? (
    <div>
      <h2
        aria-hidden
        className="text-sm font-semibold md:text-lg lg:text-xl"
        data-testid="findingHotels"
      >
        {t('findingHotels')}
      </h2>
      <span aria-live="assertive" className="sr-only">
        <span key={srCounter} role="status">
          {t('findingHotels')}
        </span>
      </span>
    </div>
  ) : (
    <div>
      <h2
        className="mr-2 font-semibold md:mr-0 md:text-lg lg:text-xl "
        data-testid="numberOfHotelsShowing"
      >
        <span aria-hidden="true">{message} </span>
      </h2>
      <span aria-atomic="true" aria-live="assertive" className="sr-only -order-1">
        <span role="status">{srMessage}</span>
      </span>
    </div>
  );
};
