import * as React from 'react';
import { forwardRef } from 'react';

import type { HotelType } from '../../providers/app-provider/app-provider.types';
import { useTranslation } from 'next-i18next';
import type { GoogleCoordinate } from '../../utils';
import { getDistanceFromCenterFmt } from '../../utils';
import HotelCard from './hotel-card';
import HotelCardSkeleton from './hotel-card-skeleton';
import { useMetrics, useWrappedRouter } from '../../hooks';

import type { ShopMultiPropAvailQuery } from '../../gql/operations';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import HotelCardCTASkeleton from './hotel-card-cta-skeleton';

export type IHotelCards = {
  isAgeBasedModalOpen: boolean;
  hotels: HotelType[];
  pricing?: Record<string, ShopMultiPropAvailQuery['shopMultiPropAvail'][0]>;
  showImages: boolean;
  isListViewOnly?: boolean;
  isLoadingState?: boolean;
  centerCoordinate: GoogleCoordinate | null;
  cardsClassName?: string;
} & React.HTMLAttributes<HTMLUListElement>;

const HotelCards = forwardRef<HTMLUListElement, IHotelCards>(
  (
    {
      isAgeBasedModalOpen,
      hotels,
      pricing,
      showImages,
      isListViewOnly,
      isLoadingState,
      centerCoordinate,
      cardsClassName,

      ...rest
    },
    forwardedRef
  ) => {
    const { pageType } = useAppState();
    const { t } = useTranslation(['hotel-card', 'rate-name-cta', 'locations-page']);
    const dispatch = useAppDispatch();
    const metrics = useMetrics();
    const { router } = useWrappedRouter();
    const { isDreams } = pageType;

    const handleDetailsClick = (ctyhocn: string, name: string, index: number) => {
      dispatch({ type: 'SET_PDP_CTYHOCN', payload: ctyhocn });
      metrics.setBrandDetail({
        ctyhocn,
        view: 'List',
        name,
        index: index + 1,
      });
    };
    // If we are in loading state show 5 hotel cards in list view in skeleton loading state
    if (isLoadingState) {
      let cnt = 0;
      return (
        <ul ref={forwardedRef} {...rest} className={cardsClassName}>
          {Array.from({ length: 6 }, () => (
            <HotelCardSkeleton
              key={cnt++}
              isListViewOnly={isListViewOnly}
              showImages={showImages}
            />
          ))}
        </ul>
      );
    }
    const keyString: string | undefined =
      Object.keys(rest).find((key) => key === 'data-testid') ?? '';

    const firstAvailableProperty = hotels.findIndex((hotel) =>
      router.asPath.includes('/search/')
        ? pricing?.[hotel?.ctyhocn]?.summary?.status?.type === 'AVAILABLE'
        : hotel?.display?.open && hotel?.leadRate?.lowest
    );

    return (
      <ul key={`ulHotelCard-${keyString}`} ref={forwardedRef} {...rest} className={cardsClassName}>
        {hotels.map((hotel, _id) => {
          const { name, ctyhocn, localization } = hotel;
          const { distanceFmt } = getDistanceFromCenterFmt(
            t,
            centerCoordinate,
            localization?.coordinate,
            router.locale
          );
          const priceInfo = pricing && pricing[ctyhocn];

          const isOccupancyExceeded = !!(pricing?.[hotel?.ctyhocn]?.statusCode === 2001);

          return (
            <React.Fragment key={`hotel-card-${ctyhocn}`}>
              <HotelCard
                distance={distanceFmt}
                isTALoadingState={isLoadingState}
                isListViewOnly={isListViewOnly}
                onDetailsClick={() => handleDetailsClick(ctyhocn, name || '', _id)}
                showImages={showImages}
                priceInfo={priceInfo}
                hotelInfo={hotel}
                isFirstAvailableProperty={_id === firstAvailableProperty}
              >
                {!isDreams && !isOccupancyExceeded && isAgeBasedModalOpen ? (
                  <HotelCardCTASkeleton />
                ) : null}
              </HotelCard>
            </React.Fragment>
          );
        })}
      </ul>
    );
  }
);

HotelCards.displayName = 'HotelCards';

export default HotelCards;
