import { MAX_POINTS_FILTER } from '../../../constants';
import { useGetMVTSelections } from '../../../hooks';
import { useAppState } from '../../../providers/app-provider';

//NHCSEARCH-5310  MVT - Max Points Filter
export const useGetMVTPointsFiltersTest = () => {
  const { shouldUsePoints } = useAppState();
  const { isLoaded: isMaxPointsFilterTestLoaded, isMVTVariant: maxPointsFilterVariant } =
    useGetMVTSelections({
      agentId: MAX_POINTS_FILTER.agentId,
      isProvisional: shouldUsePoints,
      MVTVariants: ['a', 'b', 'c'],
    });

  const isMaxPointsFilterVariant =
    isMaxPointsFilterTestLoaded && (maxPointsFilterVariant.b || maxPointsFilterVariant.c);

  return {
    isMaxPointsFilterVariant,
    isMaxPointsFilterTestLoaded,
    maxPointsFilterVariant,
  };
};
