import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useMap } from './map.context';
import { MAPS_URL, MAX_ZOOM } from '../../constants';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { useRouter } from 'next/router';
import { getLanguageDirection } from '@dx-ui/utilities-get-language-direction';
import styles from './map.module.css';
import { useHotelQuadrantsQuery } from '../../gql/queries';

type IMap = {
  children?: React.ReactNode;
  /**
   * setting `initialBounds` will use the google maps api method `fitBounds()` to set the zoom and center of the map
   */
  initialBounds?: google.maps.LatLngBoundsLiteral;
  /**
   * supports all options from https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
   */
  options?: google.maps.MapOptions;
  /**
   * whenever the map is panned or zoomed, the bounds of the map are changed.
   */
  onBoundsChange?: (bounds: google.maps.LatLngBoundsLiteral) => void;
  /**
   * whenever the map is panned, the bounds of the map are changed.
   */
  onPan?: (bounds: google.maps.LatLngBoundsLiteral) => void;
  /**
   * whenever the map is zoomed, the bounds of the map are changed.
   */
  onZoom?: (bounds: google.maps.LatLngBoundsLiteral) => void;
  /**
   * for development, it is nice to see the bounds that are passed for the initial view
   */
  showInitialBounds?: boolean;
  /**
   * for debug, see quad node boundaries and additional debug info
   */
  showQuadNodeBounds?: boolean;
};
/**
 * This is the base for the Google Maps JavaScript API
 *
 * https://developers.google.com/maps/documentation/javascript/reference/map
 */
export const MapBase = ({
  children,
  initialBounds,
  options,
  onBoundsChange,
  onPan,
  onZoom,
  showInitialBounds,
  showQuadNodeBounds,
}: IMap) => {
  const { apiKey, setMap, setInfoWindow, setInfoWindowContainer, map } = useMap();
  const mapRef = useRef<HTMLDivElement>(null);
  const infoWindowRef = useRef<HTMLDivElement>(null);
  const hasDrawnInitialBoundingBox = useRef(false);
  const hasDrawnInitialQuadNodes = useRef(false);
  const router = useRouter();
  const resetMap = useRef(true); // reset map on every search 'Update' cta click

  const { data: quadNodesData } = useHotelQuadrantsQuery(
    {},
    {
      enabled: isBrowser,
    }
  );

  useEffect(() => {
    const initMap = () => {
      const map = new google.maps.Map(mapRef.current as HTMLDivElement, {
        clickableIcons: false,
        disableDefaultUI: true,
        zoomControl: true,
        zoomControlOptions: {
          position:
            getLanguageDirection(router.locale) === 'rtl'
              ? google.maps.ControlPosition.LEFT_TOP
              : google.maps.ControlPosition.RIGHT_TOP,
        },
        restriction: {
          latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
          strictBounds: true,
        },
        maxZoom: MAX_ZOOM,
        ...options,
      });

      if (onBoundsChange) {
        map.addListener('bounds_changed', () => {
          const bounds = map.getBounds();
          if (bounds) {
            onBoundsChange(bounds.toJSON());
          }
        });
      }
      if (onPan) {
        map.addListener('dragend', () => {
          const bounds = map.getBounds();
          if (bounds) {
            onPan(bounds.toJSON());
          }
        });
      }
      if (onZoom) {
        map.addListener('zoom_changed', () => {
          const bounds = map.getBounds();
          if (bounds) {
            onZoom(bounds.toJSON());
          }
        });
      }
      setMap(map);

      if (infoWindowRef.current) {
        setInfoWindowContainer(infoWindowRef.current);
        const newInfoWindow = new google.maps.InfoWindow({
          content: infoWindowRef.current,
          disableAutoPan: true,
        });
        setInfoWindow(newInfoWindow);
      }
    };

    if (!window.google) {
      const script = document.createElement('script');
      script.src = `${MAPS_URL}&key=${apiKey}&language=${router.locale}`;
      script.defer = true;
      script.async = true;

      script.onload = initMap;
      document.body.appendChild(script);
    } else {
      initMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //if bounds changes due to shallow route re-calc bounds!
  React.useEffect(() => {
    if (initialBounds && map && resetMap) {
      map.setZoom(9);
      map.fitBounds(initialBounds);
      resetMap.current = false;
    }
    router.events.on('routeChangeComplete', () => {
      resetMap.current = true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialBounds), map, resetMap.current]);

  //Show debug initial bounds bbox
  React.useEffect(() => {
    if (showInitialBounds && map && initialBounds && !hasDrawnInitialBoundingBox.current) {
      // eslint-disable-next-line no-new
      new google.maps.Rectangle({ map, bounds: initialBounds, strokeWeight: 1, fillOpacity: 0.1 });
      hasDrawnInitialBoundingBox.current = true;
    }
  }, [showInitialBounds, map, initialBounds]);

  //Show debug of hotel quad nodes
  React.useEffect(() => {
    if (
      showQuadNodeBounds &&
      map &&
      !hasDrawnInitialQuadNodes.current &&
      quadNodesData?.hotelQuadrants
    ) {
      quadNodesData?.hotelQuadrants?.forEach((node) => {
        //rectangle representing node
        new google.maps.Rectangle({
          map,
          bounds: {
            south: Math.max(node?.bounds?.southwest?.latitude ?? -89.99, -89.99),
            west: Math.max(node?.bounds?.southwest?.longitude ?? -179.99, -179.99),
            north: Math.min(node?.bounds?.northeast?.latitude ?? 89.99, 89.99),
            east: Math.min(node?.bounds?.northeast?.longitude ?? 179.99, 179.99),
          },
          strokeWeight: 1,
          fillOpacity: 0.1,
        });
        //debug info corresponding to node
        new google.maps.Marker({
          position: {
            lat:
              ((node?.bounds?.northeast?.latitude ?? -89.99) +
                (node?.bounds?.southwest?.latitude ?? 89.99)) /
              2,
            lng:
              ((node?.bounds?.northeast?.longitude ?? 179.99) +
                (node?.bounds?.southwest?.longitude ?? -179.99)) /
              2,
          },
          map,
          label: {
            className: styles.node_debug_info,
            text: node?.id || 'no id',
          } as string | google.maps.MarkerLabel,
        });
      });
      hasDrawnInitialQuadNodes.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, quadNodesData?.hotelQuadrants]);

  return (
    <>
      <div
        className="absolute left-0 m-0 size-full p-0 md:top-0"
        id="searchGoogleMaps"
        ref={mapRef}
      />
      <div data-testid="mapMarker" ref={infoWindowRef} />
      {children}
    </>
  );
};
