import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useGetUsePoints, useMetrics, useWrappedRouter } from '../../hooks';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { InfoPopup } from '@dx-ui/osc-info-popup';
import { useIsClient } from 'usehooks-ts';
import { DisplayPoints } from './display-points-mvt/display-points';

export type UsePointsProps = {
  isInvalidOrUnavailableOffer?: boolean;
  onChange?: () => void;
};

export const UsePoints = ({ isInvalidOrUnavailableOffer, onChange }: UsePointsProps) => {
  const { router } = useWrappedRouter();
  const { t } = useTranslation('use-points');
  const { shouldUsePoints } = useAppState();
  const dispatch = useAppDispatch();
  const metrics = useMetrics();
  const isClient = useIsClient();

  const {
    isDisabled: isPointsDisabled,
    isChecked: shouldUsePointsChecked,
    infoPopupMessage,
  } = useGetUsePoints(isInvalidOrUnavailableOffer);

  const handleUsePointsChange = () => {
    dispatch({ type: 'SET_USE_POINTS', payload: !shouldUsePoints });
    void metrics.trackUsePointsChecked();
    if (onChange) onChange();
  };

  return isClient && router.isReady ? (
    <div className="ml-2 mr-0 flex-col pt-5 md:mx-2 md:px-2 rtl:mb-2">
      <div className="flex">
        <label className="label label-inline-reverse rtl:label-inline-reverse">
          <span
            className={cx('md:text-md text-sm font-semibold rtl:pr-1 rtl:pl-2 lg:text-lg', {
              'text-text-disabled': isPointsDisabled,
            })}
          >
            {t('usePoints')}
          </span>
          <input
            checked={shouldUsePointsChecked}
            className="form-checkbox"
            data-testid="searchByUsePoints"
            disabled={isPointsDisabled}
            onChange={handleUsePointsChange}
            type="checkbox"
          />
        </label>
        {infoPopupMessage && (
          <span className="m-1 flex">
            <InfoPopup testId="dayUseRateDisclaimer" label={t('pointsInfo')}>
              <span className="flex w-40">{t(infoPopupMessage)}</span>
            </InfoPopup>
          </span>
        )}
      </div>
      <DisplayPoints />
    </div>
  ) : null;
};
