import { useAuth } from '@dx-ui/framework-auth-provider';
import { useGuest_GuestStays_HotelQuery } from '../../../../gql/queries';
import { useGetMVTSelections, useWrappedRouter } from '../../../../hooks';
import { DEFAULT_LANGUAGE } from '@dx-ui/utilities-dates';
import { useMemo } from 'react';
import { SHOW_MYWAY_BENEFITS } from '../../../../constants';
import type { Maybe } from '../../../../gql/types';

type MyWayBenefits =
  | { selected?: Maybe<boolean> | undefined; description?: Maybe<string> | undefined }[]
  | undefined;
type GetMyWayBenefitDetailsProps = {
  ctyhocn: string | undefined;
  brandCode: string | undefined;
  hotelCountry: string | undefined;
};
const TIER_APPLICABLE = ['D', 'G'];

export const useGetMyWayBenefitDetails = ({
  ctyhocn,
  brandCode,
  hotelCountry,
}: GetMyWayBenefitDetailsProps) => {
  const { guestInfo, isAuthenticated } = useAuth();
  const { router } = useWrappedRouter();
  const isDiamondOrGoldTier = TIER_APPLICABLE.includes(guestInfo?.hhonors?.summary?.tier || '');
  const { isLoaded: isMyWayBenefitTestLoaded, isMVTVariant } = useGetMVTSelections({
    agentId: SHOW_MYWAY_BENEFITS.agentId,
    MVTVariants: ['a', 'b'],
    isProvisional: isAuthenticated && isDiamondOrGoldTier,
  });

  const showMyWayBenefits = isMyWayBenefitTestLoaded && isMVTVariant.b;

  const { data, isLoading, isFetching } = useGuest_GuestStays_HotelQuery(
    {
      guestId: guestInfo?.guestId || 0,
      language: router.locale || DEFAULT_LANGUAGE,
    },
    { enabled: showMyWayBenefits && !!guestInfo?.guestId }
  );
  const { myWayBenefits, isHotelBenefits } = useMemo(() => {
    let guestHotelBenefits: MyWayBenefits = [];
    let isHotelBenefits = false;
    if (!isLoading) {
      guestHotelBenefits = data?.guest?.stays
        ?.find((stay) => stay?.hotel?.ctyhocn === ctyhocn)
        ?.guestBenefits?.hotelBenefitSummary?.items.filter(
          (item) => item.selected && !!item.description
        );
      if (guestHotelBenefits) isHotelBenefits = true;
      else {
        const brandHotelBenefitSummary = data?.guest?.hotelBenefitOptions?.find(
          (benefitOptions) => benefitOptions?.brandCode === brandCode
        )?.hotelBenefitSummary;
        guestHotelBenefits = brandHotelBenefitSummary?.items.filter(
          (item) => item.selected && !!item.description
        );
        //Add Food and Beverage benefit
        const foodAndBeverageDesc =
          hotelCountry === 'US'
            ? brandHotelBenefitSummary?.usFoodAndBeverageHeadline
            : brandHotelBenefitSummary?.nonUSFoodAndBeverageHeadline;
        if (foodAndBeverageDesc)
          guestHotelBenefits?.push({ selected: true, description: foodAndBeverageDesc });
      }
    }

    return { myWayBenefits: guestHotelBenefits, isHotelBenefits };
  }, [
    brandCode,
    ctyhocn,
    data?.guest?.hotelBenefitOptions,
    data?.guest?.stays,
    hotelCountry,
    isLoading,
  ]);

  return {
    showMyWayBenefits: showMyWayBenefits && !!myWayBenefits?.length,
    isLoading: isLoading || isFetching,
    myWayBenefits,
    isHotelBenefits,
  };
};
