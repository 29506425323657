export default function HotelCardCTASkeleton() {
  return (
    <div
      aria-hidden
      className="flex h-full flex-col items-end justify-end space-y-2 pt-2"
      data-testid="HotelCardCTASkeleton"
    >
      <div className="bg-bg-alt h-8 w-16 animate-pulse" />
      <div className="bg-bg-alt h-4 w-1/2 animate-pulse" />
      <div className="bg-bg-alt h-10 w-32 animate-pulse rounded" />
    </div>
  );
}
