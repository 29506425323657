import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useFilterState } from '../../providers/filter-provider';
import type { HotelAmenityId, Maybe } from '../../gql/types';
import type { ActiveFiltersState } from './filter.constants';
import { useMetrics, useGetMVTSelections } from '../../hooks';
import { GOALS, LAS_VEGAS_CUSTOM_SUGGESTED_FILTERS } from '../../constants';
import { LAS_VEGAS_MATCH_ID, useSuggestedFilters } from '../../hooks/use-suggested-filters';
import Icon, { AmenityIcons } from '@dx-ui/osc-icon';
import { HotelAttributeIds } from './filter.constants';
import { sendReward } from '@dx-ui/framework-conductrics';
import { TaxonomyList } from '@dx-ui/osc-taxonomy-list';

export type AmenityItem = {
  id: string;
  name: string;
  hint?: string | null;
  count?: number;
};
type SuggestedFilterButtonsProps = {
  amenities?: AmenityItem[];
  isConductricsLoaded?: boolean;
  onFilterCriteriaChange: (activeFiltersState: ActiveFiltersState) => void;
  visibleHotels: string[];
  matchId?: Maybe<string>;
  saleFilter?: { showSaleFilter: boolean; saleFilterCount: number };
};

type SuggestedFiltersAmenitiesProps = {
  suggestedFilterOrderedList: AmenityItem[];
  onFilterCriteriaChange: (activeFiltersState: ActiveFiltersState) => void;
  showSaleFilter?: boolean;
  suggestedFilterMVTVariant?: Record<string, boolean>;
  matchId?: Maybe<string>;
};

/*
 * SuggestedFilterButtons
 * Displays the Suggested Filter Component
 */
export const SuggestedFilterButtons = ({
  amenities = [],
  isConductricsLoaded,
  onFilterCriteriaChange,
  visibleHotels,
  matchId,
  saleFilter,
}: SuggestedFilterButtonsProps) => {
  const { isAtleastOneSuggestedFilter, topSuggestedFilters } = useSuggestedFilters({
    visibleHotels,
    amenities,
    matchId,
    saleFilter,
  });
  const suggestedFiltersOrderedList = Object.values(topSuggestedFilters);

  const showSuggestedFilters = isAtleastOneSuggestedFilter || saleFilter?.showSaleFilter;

  if (showSuggestedFilters) {
    if (!isConductricsLoaded || !amenities.length) return <SuggestedFiltersShimmer />;
    else if (isConductricsLoaded && !!visibleHotels.length)
      return (
        <>
          <SuggestedFiltersTitle />
          <SuggestedFiltersAmenities
            onFilterCriteriaChange={onFilterCriteriaChange}
            suggestedFilterOrderedList={suggestedFiltersOrderedList}
            showSaleFilter={saleFilter?.showSaleFilter}
          />
        </>
      );
  }
  return null;
};

/*
 * SuggestedFiltersShimmer
 * Returns the Suggested Filters Shimmer State
 */
const SuggestedFiltersShimmer = () => (
  <>
    <div className="flex" data-testid="suggested-filters-shimmer">
      <div className="bg-bg-alt mb-4 h-6 w-32 animate-pulse" />
    </div>
    <div className="flex h-32 lg:h-16">
      <div className="bg-bg-alt mr-2 w-20 animate-pulse lg:mr-1 lg:h-16 lg:w-32" />
      <div className="bg-bg-alt mr-2 w-20 animate-pulse lg:mr-1 lg:h-16 lg:w-32" />
      <div className="bg-bg-alt mr-2 w-20 animate-pulse lg:mr-1 lg:h-16 lg:w-32" />
      <div className="bg-bg-alt mr-2 w-20 animate-pulse lg:mr-1 lg:h-16 lg:w-32" />
    </div>
  </>
);

/*
 * Returns Suggest Filters Title
 */
const SuggestedFiltersTitle = () => {
  const { t } = useTranslation('filters');
  return (
    <h2 className="label mr-1 pb-1 font-semibold tracking-tight rtl:mx-0">
      {t('suggestedFiltersLabel')}
    </h2>
  );
};

const SuggestedFiltersAmenities = ({
  onFilterCriteriaChange,
  suggestedFilterOrderedList,
  matchId,
}: SuggestedFiltersAmenitiesProps) => {
  const { isMVTVariant: lasVegasSuggestedFilterMVTVariant, isLoaded: isConductricsLoaded } =
    useGetMVTSelections({
      agentId: LAS_VEGAS_CUSTOM_SUGGESTED_FILTERS.agentId,
      isProvisional: matchId === LAS_VEGAS_MATCH_ID,
      MVTVariants: ['a', 'b'],
    });
  const isLasVegasCustomSuggestedFiltersEnabled =
    isConductricsLoaded && !!lasVegasSuggestedFilterMVTVariant.b;
  const activeFiltersState = useFilterState();
  const metrics = useMetrics();

  const resetSuggestedFilter = (amenityId: HotelAmenityId | typeof HotelAttributeIds.sale) => {
    const attributeFilters = [...activeFiltersState.attributeFilters];
    const attributeIndex = attributeFilters.indexOf(amenityId);
    const amenityFilters = [...activeFiltersState.amenityFilters];
    const amenityIndex = amenityFilters.indexOf(amenityId);

    if (amenityId === HotelAttributeIds.sale) {
      if (attributeIndex !== -1) attributeFilters.splice(attributeIndex, 1);
      else attributeFilters.push(amenityId);
    } else {
      if (amenityIndex !== -1) amenityFilters.splice(amenityIndex, 1);
      else amenityFilters.push(amenityId);
    }

    const updatedActiveFiltersState = {
      ...activeFiltersState,
      amenityFilters,
      attributeFilters,
    };

    onFilterCriteriaChange(updatedActiveFiltersState);
  };

  const handleOnClick = async (amenityId: HotelAmenityId | typeof HotelAttributeIds.sale) => {
    //NHCSEARCH-5395
    if (
      isLasVegasCustomSuggestedFiltersEnabled &&
      (amenityId === 'spa' || amenityId === 'casino')
    ) {
      sendReward(GOALS.casinoOrSpaSuggestedFilterApplied);
    }
    resetSuggestedFilter(amenityId);
    sendReward(GOALS.suggestedFilterEngagement);

    await metrics.trackSuggestedFilters(amenityId);
  };

  return (
    <div className="pb-2">
      <TaxonomyList
        labelledBy="suggestedFilters"
        testId="suggested-filters"
        name="Suggested Filters"
      >
        {suggestedFilterOrderedList?.map((amenity) => {
          const amenityListCount = amenity?.count;
          const amenitySelected =
            activeFiltersState.amenityFilters.includes(amenity?.id ?? '') ||
            activeFiltersState.attributeFilters.includes(amenity?.id);
          return amenityListCount ? (
            <div className="h-10 px-1">
              <button
                type="button"
                name={`${amenity?.name}  (${amenityListCount})`}
                aria-label={`${amenity?.name}  (${amenityListCount})`}
                className={cx(
                  'btn btn-primary-outline focus:border-primary flex items-center rounded-full gap-x-2',
                  {
                    'text-bg bg-primary outline-none': amenitySelected,
                  }
                )}
                onClick={() => handleOnClick(amenity?.id as HotelAmenityId)}
              >
                <span>
                  <Icon name={AmenityIcons[amenity.id as HotelAmenityId] || amenity.id} size="md" />
                </span>
                <span aria-hidden className="text-sm font-bold">
                  <span>{`${amenity.name} (${amenityListCount})`}</span>
                </span>
              </button>
            </div>
          ) : null;
        })}
      </TaxonomyList>
    </div>
  );
};
