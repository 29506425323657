import { useTranslation } from 'next-i18next';
import { useGetMyWayBenefitDetails } from './hooks/use-get-myway-benefits';
import { useBrandDetails } from '../../../hooks';
import Icon from '@dx-ui/osc-icon';

type MyWayBenefitsProps = {
  brandCode: string | undefined;
  ctyhocn: string | undefined;
  hotelCountry: string | undefined;
};
export const MyWayBenefits = ({ brandCode, ctyhocn, hotelCountry }: MyWayBenefitsProps) => {
  const { t } = useTranslation('hotel-card');
  const brandInfo = useBrandDetails({ brandCode });

  const { isLoading, showMyWayBenefits, myWayBenefits, isHotelBenefits } =
    useGetMyWayBenefitDetails({
      brandCode,
      ctyhocn,
      hotelCountry,
    });

  if (!showMyWayBenefits) return null;

  return isLoading ? (
    <div className="h-40 w-full">
      <div className="bg-bg-alt my-2 h-5 w-40 animate-pulse" />
      <div className="bg-bg-alt my-2 h-5 w-40 animate-pulse" />
      <div className="bg-bg-alt my-2 h-5 w-40 animate-pulse" />
    </div>
  ) : (
    <div className="mb-2 gap-y-2">
      <div className="font-sans text-sm font-bold">
        {isHotelBenefits
          ? t('yourMyWayBenefits')
          : t('yourMyWayBenefitsAt', { brandName: brandInfo.name })}
      </div>
      <div className="flex flex-wrap gap-y-0.5">
        {myWayBenefits?.map((benefit) => {
          return (
            <div
              className="flex items-start font-sans text-sm font-normal"
              key={benefit.description}
            >
              <Icon name="check" size="sm" />
              <span dangerouslySetInnerHTML={{ __html: benefit.description as string }} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
