import { useTranslation } from 'next-i18next';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { sendReward } from '@dx-ui/framework-conductrics';
import { COMPARE_ONBOARDING, GOALS } from '../../constants';
import { useGetMVTSelections, useWrappedRouter } from '../../hooks';
import { FeatureToolTip } from './feature-tool-tip';
import { useLocalStorage } from 'usehooks-ts';
import { useRef, useState } from 'react';

type CompareToggleProps = {
  onChange?: () => void;
};

export const CompareHotelsToggle = ({ onChange }: CompareToggleProps) => {
  const { t } = useTranslation('compare-hotels');
  const { safeQueryParams: queryParameters, router } = useWrappedRouter();

  const { compareHotelsToggle, hotelsToCompare } = useAppState();
  const dispatch = useAppDispatch();

  //NHCSEARCH-5449 | Compare Hotels Onboarding MVT
  const toggleRef = useRef<HTMLInputElement>(null);
  const {
    isLoaded: isOnboardingMVTLoaded,
    isMVTVariant: { b: isOnboardingVarB, c: isOnboardingVarC },
  } = useGetMVTSelections({
    agentId: COMPARE_ONBOARDING.agentId,
    MVTVariants: ['b', 'c'],
    isProvisional: router.locale === 'en',
  });
  const [isOnboarded, setIsOnboarded] = useLocalStorage('compare-hotels-onboarded', false);
  const [isCompareToolTipOpen, setIsCompareToolTipOpen] = useState(true);
  const handleToolTipClose = () => {
    if (isOnboardingVarB) setIsOnboarded(true);
    setIsCompareToolTipOpen(false);
    if (toggleRef.current) toggleRef.current.focus();
  };
  const showCompareOnboardingToolTip = isOnboardingMVTLoaded && !isOnboarded;

  const handleInputChange = () => {
    if (onChange) onChange();
    if (!compareHotelsToggle) sendReward(GOALS.compareHotelsToggleOn);
    //when hotels to compare still selected and  toggle is set to false - remove previous selections
    if (!compareHotelsToggle && hotelsToCompare.length)
      dispatch({ type: 'SET_HOTELS_TO_COMPARE', payload: [] });
    dispatch({ type: 'SET_COMPARE_HOTELS_TOGGLE', payload: !compareHotelsToggle });
    if (isOnboardingVarB) setIsOnboarded(true);
    setIsCompareToolTipOpen(false);
  };
  return !queryParameters?.datesFlex ? (
    <div className="relative ml-1 mr-2 pt-5 rtl:mb-2">
      {/* NHCSEARCH-5449 | Compare Hotels Onboarding MVT */}
      <FeatureToolTip
        absolutePosition="-left-2 -top-10"
        tailPosition="left-6"
        message={t('newFeature')}
        onDismiss={handleToolTipClose}
        containerClass="w-[115px] py-3 pl-3 pr-4"
        shouldShowFeatureToolTip={
          !!(showCompareOnboardingToolTip && isOnboardingVarB && isCompareToolTipOpen)
        }
      />
      <FeatureToolTip
        absolutePosition="[@media(max-width:422px)]:-right-24 [@media(min-width:423px)]:-right-48  [@media(min-width:588px)]:-right-2 [@media(min-width:768px)]:-right-44 [@media(min-width:876px)]:-right-2 [@media(min-width:1200px)]:left-0 -top-36"
        tailPosition="[@media(max-width:422px)]:left-1/3 [@media(min-width:423px & max-width:587px)]:left-28 [@media(min-width:588px)]:left-48 [@media(min-width:768px)]:left-6 [@media(min-width:876px)]:left-48 [@media(min-width:1124px)]:left-2 [@media(min-width:1201px)]:-translate-x-44"
        title={t('compareAndBook')}
        message={t('compareKeyFeatures')}
        onDismiss={handleToolTipClose}
        cta={{ label: t('ok'), onClick: handleToolTipClose }}
        containerClass="w-[343px] p-3"
        shouldShowFeatureToolTip={
          !!(showCompareOnboardingToolTip && isOnboardingVarC && isCompareToolTipOpen)
        }
      />
      <div className="flex">
        <label className="flex cursor-pointer font-bold">
          <div className="relative mr-1 inline-flex">
            <input
              className="peer sr-only"
              type="checkbox"
              onChange={handleInputChange}
              ref={toggleRef}
            />
            <div className="peer-checked:bg-hilton peer-focus:ring-hilton peer-checked:after:border-bg after:border-bg-disabled after:bg-bg peer h-6 w-12 rounded-full bg-[#CFCFCF] ring-offset-1 after:absolute after:top-[2px] after:size-5 after:rounded-full after:border after:transition-all after:content-[''] peer-focus:outline-none peer-focus:ring-2 motion-reduce:transition-none ltr:after:left-[4px] peer-checked:ltr:after:translate-x-full rtl:after:right-[4px] peer-checked:rtl:after:-translate-x-full" />
          </div>
          <span className="text-sm first-letter:capitalize rtl:pr-2">{t('compareHotels')}</span>
        </label>
      </div>
    </div>
  ) : null;
};
