import type { TFunction } from 'i18next';
import type {
  HotelImageRatioName,
  HotelImageVariantSize,
  HotelQuery,
  Maybe,
} from '../../gql/types';
import { getAspectRatioUrl } from '@dx-ui/utilities-images';
import { getNoPhotoAvailable } from '../../utils';
import type { IconNames } from '@dx-ui/osc-icon';

export const MOBILE_COMPARE_LIMIT = 2;
export const TABLET_COMPARE_LIMIT = 3;
export const DESKTOP_COMPARE_LIMIT = 5;

export const getParkingType = (
  parking: NonNullable<HotelQuery['hotel']>['parking'] | null | undefined,
  t: TFunction<['hotel-card', 'rate-name-cta', 'locations-page', 'compare-hotels'], undefined>
) => {
  switch (true) {
    case parking?.hasFreeParking:
      return t('compare-hotels:freeParking');
    case parking?.hasValetParking:
      return t('compare-hotels:valetParking', {
        price: parking?.valetParkingCharge?.chargeAmountFmt,
      });
    case parking?.hasSelfParking && !!parking?.selfParkingCharge?.chargeAmountFmt:
      return t('compare-hotels:selfParking', {
        price: parking?.selfParkingCharge?.chargeAmountFmt,
      });
    default:
      return t('compare-hotels:noParking');
  }
};

export const getRoomTypes = (
  rooms: NonNullable<HotelQuery['hotel']>['roomTypes'] | null | undefined,
  t: TFunction<['hotel-card', 'rate-name-cta', 'locations-page', 'compare-hotels'], undefined>
) => {
  const types = new Set<string>();
  rooms?.forEach((room) => {
    if (room.suite) types.add(t('compare-hotels:suites'));
    else if (room.features.some((feature) => feature.name === 'Connecting rooms'))
      types.add(t('compare-hotels:connectedRooms'));
    else if (room?.numBeds)
      types.add(
        t('compare-hotels:bedWithCount', {
          count: room.numBeds,
        })
      );
  });
  const roomTypes: { type: string; icon: (typeof IconNames)[number] }[] = [];
  types?.forEach((type) => {
    if (type === t('compare-hotels:suites')) roomTypes.push({ type, icon: 'suites' });
    else if (type === t('compare-hotels:connectedRooms'))
      roomTypes.push({ type: t('compare-hotels:connectedRooms'), icon: 'connecting-rooms' });
    else
      roomTypes.push({
        type,
        icon: 'bed',
      });
  });
  return roomTypes.sort((a, b) => a.type.localeCompare(b.type));
};
export const getModalSize = ({
  isMobile,
  isDesktop,
  compareCtyhocnsCount,
}: {
  isMobile: boolean;
  isDesktop: boolean;
  compareCtyhocns?: string[];
  compareCtyhocnsCount: number;
}) => {
  switch (true) {
    case isMobile:
      return 'lg';
    case isDesktop && compareCtyhocnsCount < 3:
      return '2xl';
    case isDesktop && compareCtyhocnsCount >= 4:
      return 'container';
    case isDesktop && compareCtyhocnsCount < 4:
      return '5xl';
    default:
      return 'xl';
  }
};

type CompareImage = {
  altText?: Maybe<string>;
  ratios: Array<{ url?: Maybe<string>; size: HotelImageRatioName }>;
  variants: Array<{ size: HotelImageVariantSize; url?: Maybe<string> }>;
};
export const getCompareImage = (image?: CompareImage | null) => {
  if (!!image?.ratios?.[0]) {
    const { imgUrl } = getAspectRatioUrl({
      src: image?.ratios?.[0]?.url,
      width: 326,
    });
    return { url: imgUrl, altText: image?.altText };
  }
  const noPhoto = getNoPhotoAvailable('xs')?.[0]?.variants[0];
  return { url: noPhoto?.url };
};
