import type * as React from 'react';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useMap } from './map.context';

type IMapInfoWindow = {
  children: React.ReactNode;
  /**
   * This event is fired when the close button is clicked.
   */
  onCloseClick?: () => void;
  position: google.maps.InfoWindowOptions['position'];
};

/**
 * Used as a wrapper to insert any React Component into
 */
const MapInfoWindow = ({ children, onCloseClick, position }: IMapInfoWindow) => {
  const { infoWindow, infoWindowContainer } = useMap();

  useEffect(() => {
    if (infoWindow) {
      if (position) {
        infoWindow.setPosition(position);
      }
      if (onCloseClick) {
        infoWindow.addListener('closeclick', onCloseClick);
      }
    }
    return () => {
      if (infoWindow) {
        google.maps.event.clearListeners(infoWindow, 'closeclick');
      }
    };
  }, [infoWindow, children, onCloseClick, position]);

  return infoWindowContainer ? createPortal(children, infoWindowContainer) : null;
};

export default MapInfoWindow;
