import cx from 'classnames';
import HotelCardCTASkeleton from './hotel-card-cta-skeleton';
import { useMediaQuery } from 'usehooks-ts';

type HotelCardSkeletonProps = {
  isListViewOnly?: boolean;
  showImages?: boolean;
};

const HotelCardSkeleton = ({ isListViewOnly, showImages }: HotelCardSkeletonProps) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  // Do not modify the classname string without notifying the team
  const classname = {
    wrapperHotelCardHeaderAndCardContentClassName: cx('w-full md:max-w-[440px]', {
      'pl-0 py-2 md:pl-2 md:pr-2 pt-2 flex-grow': showImages && !isDesktop,
      ' md:min-w-[343px]': showImages && isDesktop,
      'pt-2 sm:w-5/7 sm:pt-0 sm:pl-4 md:w-3/4': showImages && isListViewOnly,
    }),
    hotelCardImage: 'flex-shrink-0 w-full',
  };

  return (
    <li
      className={cx(
        'flex flex-col rounded-lg min-w-[343px] w-full md:max-w-[440px] justify-self-center',
        {
          ' last:mb-4': !isDesktop,
        }
      )}
      data-testid="hotel-card-skeleton"
    >
      {/* Hotel Image placeholder */}
      {showImages && (
        <div className={classname.hotelCardImage} data-testid="hotel-card-image-shimmer">
          <div className="bg-bg-alt pb-2/3 relative h-0 w-full animate-pulse overflow-hidden" />
        </div>
      )}
      {/* Hotel Card Details Wrapper */}
      <div
        className={classname.wrapperHotelCardHeaderAndCardContentClassName}
        data-testid="wrapper-hotel-card-details-shimmer"
      >
        {/* Hotel Header placeholder */}
        <div
          className="bg-bg-alt my-2 h-4 w-full animate-pulse leading-snug rtl:mr-2"
          data-testid="listView-property-name-shimmer"
        />
        {/* Hotel Card Details Wrapper */}
        <div className="flex">
          <div className="w-1/2 sm:w-full md:w-3/5 xl:grow  rtl:mr-2">
            {/* Hotel Details placeholder */}
            <div
              className="bg-bg-alt mb-2 block h-4 w-24 animate-pulse self-start"
              data-testid="hotel-details-button-shimmer"
            />
            {/* Hotel Distance Marker placeholder */}
            <div
              className="bg-bg-alt h-4 w-16 animate-pulse whitespace-nowrap"
              data-testid="distance-marker-shimmer"
            />
            {/* Trip Advisor placeholder */}
            <div
              className="bg-bg-alt mt-2 h-4 w-32 animate-pulse"
              data-testid="trip-advisor-rating-shimmer"
            />
            {/* Trip Advisor Circle placeholder */}
            <div
              className="bg-bg-alt mt-2 h-4 w-20 animate-pulse"
              data-testid="trip-advisor-circle-review-shimmer"
            />
            {/* Trip Advisor Based on Reviews placeholder */}
            <div
              className="bg-bg-alt mt-2 h-4 w-28 animate-pulse"
              data-testid="trip-advisor-reviews-shimmer"
            />
          </div>
          {/* Hotel Details CTA shimmer */}
          <div className="flex w-1/2 shrink-0 justify-end overflow-hidden">
            <HotelCardCTASkeleton />
          </div>
        </div>
      </div>
    </li>
  );
};

export default HotelCardSkeleton;
