import type { MapLanguage, BrandCode, SortByValues, Child } from '@dx-ui/framework-uri-builder';
import { searchUriBuilder } from '@dx-ui/framework-uri-builder';
import { RoomsModal } from '@dx-ui/osc-rooms';
import { parseISO } from 'date-fns';
import isNumber from 'lodash/isNumber';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import type { QueryParameters } from '../../constants';
import type { ShopMultiPropAvailQuery } from '../../gql/operations';
import { useBrandDetails, useWrappedRouter } from '../../hooks';
import { getTARatingValue, getValidFilters, isPriceRangeValid } from '../filters/filter-utils';
import { hasAgeBased, hasChildAges, hasTMTPRatesSelected } from './age-based-modal-utils';
import { mapRatesToSearchUriBuilder } from '../osc-search-form/search-form.utils';
import { useAppState } from '../../providers/app-provider';
import type { FieldValues } from 'react-hook-form';
import { useFilterState } from '../../providers/filter-provider';

const AgeBasedModal = ({
  isAgeBasedModalOpen,
  setIsAgeBasedModalOpen,
  pricing,
}: {
  isAgeBasedModalOpen: boolean;
  setIsAgeBasedModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pricing?: Record<string, ShopMultiPropAvailQuery['shopMultiPropAvail'][0]>;
}) => {
  const { t } = useTranslation(['hotel-card', 'osc-shop-form-wrapper']);
  const { hasConnectingRooms, selectedCurrency, sortType, shouldUsePoints } = useAppState();
  const activeFiltersState = useFilterState();

  const { safeQueryParams: queryParameters, router } = useWrappedRouter();

  const isAgeBased = hasAgeBased(queryParameters, pricing) && hasChildAges(queryParameters);

  const { code: brandCode } = useBrandDetails({});

  React.useEffect(() => {
    if (isAgeBased) {
      setIsAgeBasedModalOpen(isAgeBased);
    }
  }, [queryParameters, pricing, isAgeBased, setIsAgeBasedModalOpen]);

  const roomInfo =
    queryParameters &&
    queryParameters.rooms.map((room) => ({
      adults: room.adults,
      kids: room.children,
      childAges: room.childAges?.map(({ age }) => ({ age: isNumber(age) ? age : '' })),
    }));

  const handleRoomInfoSubmit = (data: FieldValues) => {
    const roomInfo = data?.rooms?.map((room: { adults: number; children: Child[] }) => ({
      adults: room?.adults,
      children: room?.children,
    }));

    const path =
      window.location.pathname.split('/').includes('go-hilton') ||
      hasTMTPRatesSelected(queryParameters as QueryParameters)
        ? 'go-hilton'
        : 'search';

    if (queryParameters) {
      const { offerId, pnd, specPlan, fromId, travelAgentId, requestedRatesOnly } = queryParameters;

      const url = searchUriBuilder({
        brandCode: brandCode as keyof BrandCode,
        locale: router.locale as MapLanguage,
        urlParams: {
          content: queryParameters?.content,
          dates: {
            arrivalDate: parseISO(queryParameters.arrivalDate),
            departureDate: parseISO(queryParameters.departureDate),
            flexibleDates: queryParameters.datesFlex,
          },
          placeId: queryParameters.placeId,
          query: queryParameters.query,
          sortBy: sortType as SortByValues,
          f_amenityIds: getValidFilters(activeFiltersState?.amenityFilters),
          f_brandCodes: getValidFilters(activeFiltersState?.brandFilters),
          f_price: isPriceRangeValid(activeFiltersState?.priceFilter)
            ? activeFiltersState?.priceFilter
            : undefined,
          f_tripAdvisorRatings: getTARatingValue(activeFiltersState?.ratingsFilter),
          availableHotelsOnly: activeFiltersState?.showAvailableHotels,
          rates: {
            ...mapRatesToSearchUriBuilder(queryParameters, shouldUsePoints),
            ...(offerId ? { offerId } : {}),
            ...(pnd ? { pnd } : {}),
            ...(specPlan ? { spec_plan: specPlan.join(',') } : {}),
            ...(fromId ? { fromId } : {}),
            ...(travelAgentId ? { travelAgentId } : {}),
            ...(requestedRatesOnly ? { requestedRatesOnly } : {}),
          },
          numRooms: roomInfo?.length || 1,
          rooms: roomInfo,
          sessionToken: queryParameters.sessionToken,
          specialRateTokens: queryParameters.specialRateTokens,
          displayCurrency: selectedCurrency || '',
          adjoiningRoomStay: hasConnectingRooms,
          maxPoints: queryParameters.maxPoints,
        },
        path,
        relative: true,
      });

      window.location.href = url;
    }
  };

  return isAgeBasedModalOpen ? (
    <RoomsModal
      ageRange={{ min: 0, max: 18 }}
      agesRequired
      ariaLabel={t('hotel-card:roomModalAria')}
      isOpen
      occupancyLimitMessage={t('osc-shop-form-wrapper:maxGuestsSingleRoom')}
      onSubmit={handleRoomInfoSubmit}
      useFormProps={{
        defaultValues: {
          rooms: roomInfo?.map((room) => ({
            adults: room.adults,
            children: room.childAges.map((child) => ({
              age: child?.age,
            })),
          })),
        },
      }}
    />
  ) : null;
};

export { AgeBasedModal };
