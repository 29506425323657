import type * as React from 'react';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { useIsClient } from 'usehooks-ts';
import getBreakpoint from '../../utils/get-breakpoint';
import type { LabelValue } from '../../utils';

type DropdownProps = {
  options: LabelValue[];
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string | number | readonly string[];
  label: string;
};
const Dropdown = ({ options, handleChange, value, label }: DropdownProps) => {
  const isClient = useIsClient();
  const isMobile = isBrowser && window.innerWidth < (getBreakpoint('sm', true) as number);

  return isClient ? (
    <div
      className="z-1 ml-2 mr-1 w-32 items-center text-sm md:ml-0 md:w-64"
      data-testid="sortByDropdown"
    >
      <label
        className="label mr-1 whitespace-nowrap text-xs font-semibold tracking-tight md:text-sm rtl:mx-0"
        htmlFor="selectSortBy"
      >
        <span className="pb-1">{label}</span>
      </label>
      <select
        aria-label={label}
        className="form-select w-32 text-sm leading-4 md:w-full md:text-base"
        data-testid="selectSortBy"
        id="selectSortBy"
        name={label}
        onChange={(evt) => handleChange(evt)}
        role="presentation"
        style={{ ...(isMobile && { margin: 0 }) }}
        title={label}
        value={value}
      >
        {options?.map(
          (option) =>
            !!option.isVisible && (
              <option
                data-testid={option.testIdLabel}
                disabled={option.isDisabled}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            )
        )}
      </select>
    </div>
  ) : null;
};

export default Dropdown;
