import { ControlClose } from '@dx-ui/osc-controls';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { useEffect, useRef } from 'react';

export const FeatureToolTip = ({
  absolutePosition,
  cta,
  containerClass,
  message,
  onDismiss,
  shouldShowFeatureToolTip,
  tailPosition,
  title,
}: {
  absolutePosition: string;
  cta?: { label: string; onClick: () => void };
  containerClass?: string;
  message?: string;
  onDismiss: () => void;
  shouldShowFeatureToolTip: boolean;
  tailPosition?: string;
  title?: string;
}) => {
  const { t } = useTranslation('osc-dialog');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) ref.current.focus();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onDismiss?.();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onDismiss]);

  return shouldShowFeatureToolTip ? (
    <div className={cx('absolute z-50', absolutePosition)} ref={ref} tabIndex={-1} role="tooltip">
      <div
        className={cx(
          'bg-tier-diamond text-bg relative flex flex-col gap-2 rounded text-base shadow-[0px_4px_4px_0px_rgba(0,0,0,0.40)]',
          containerClass
        )}
      >
        {title ? <div className="font-bold">{title}</div> : null}
        <div>{message}</div>
        {cta?.label ? (
          <button
            type="button"
            onClick={cta.onClick}
            className="border-bg self-start rounded-lg border px-4 py-2 text-sm"
          >
            {cta.label}
          </button>
        ) : null}
        <div
          className={cx(
            'border-t-tier-diamond absolute bottom-[-8px] size-0 border-x-8 border-t-8 border-transparent',
            tailPosition
          )}
        />
      </div>
      {!cta?.label ? (
        <ControlClose
          size="xs"
          onClick={onDismiss}
          className="text-bg absolute end-0.5 top-0.5"
          label={t('close')}
        />
      ) : null}
    </div>
  ) : null;
};
