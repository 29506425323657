export const CompareSkeleton = ({ numHotels }: { numHotels: number }) => (
  <div className="flex w-full">
    {Array.from({ length: numHotels })
      .fill('_')
      .map((_, i) => (
        <div
          className="border-border grid size-full min-w-40 max-w-[336px] items-start border"
          // eslint-disable-next-line react/no-array-index-key
          key={`compare-skeleton-${i}`}
        >
          {/* image */}
          <div className="bg-bg-alt border-border h-48 w-full animate-pulse border-b" />
          {/* title */}
          <div className="border-border w-full self-start border-b p-2">
            <div className="bg-bg-alt h-6  animate-pulse" />
          </div>
          {/* trip advisor */}
          <div className="border-border flex w-full flex-col justify-start gap-1 border-b p-2">
            <div className=" bg-bg-alt h-3 w-32 animate-pulse" />
            <div className=" bg-bg-alt h-6 w-28 animate-pulse" />
            <div className=" bg-bg-alt h-3 w-32 animate-pulse" />
          </div>
          {/* pricing */}
          <div className="border-border flex w-full flex-col justify-start gap-1 border-b p-2">
            <div className=" bg-bg-alt h-12 w-24 animate-pulse" />
            <div className=" bg-bg-alt h-4 w-32 animate-pulse" />
          </div>
          {/* parking */}
          <div className="border-border flex w-full flex-col gap-1 self-start border-b p-2">
            <div className=" bg-bg-alt h-6 w-32 animate-pulse" />
          </div>
          {/* amenities */}
          <div className="border-border flex w-full flex-col gap-1 self-start border-b p-2">
            <div className=" bg-bg-alt h-6 w-32 animate-pulse" />
            <div className=" bg-bg-alt h-6 w-32 animate-pulse" />
            <div className=" bg-bg-alt h-6 w-32 animate-pulse" />
            <div className=" bg-bg-alt h-6 w-32 animate-pulse" />
          </div>
          {/* rooms */}
          <div className="border-border flex w-full flex-col gap-1 self-start border-b p-2">
            <div className=" bg-bg-alt h-4 w-32 animate-pulse" />
            <div className=" bg-bg-alt h-6 w-32 animate-pulse" />
            <div className=" bg-bg-alt h-6 w-32 animate-pulse" />
            <div className=" bg-bg-alt h-6 w-32 animate-pulse" />
            <div className=" bg-bg-alt h-6 w-32 animate-pulse" />
          </div>
          {/* CTA */}
          <div className="flex w-full flex-col items-center gap-1 p-2">
            <div className=" bg-bg-alt h-10 w-full animate-pulse" />
            {numHotels > 2 ? <div className=" bg-bg-alt h-6 w-1/2 animate-pulse" /> : null}
          </div>
        </div>
      ))}
  </div>
);
