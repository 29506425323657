import { useMemo } from 'react';
import type { HotelType } from '../providers/app-provider/app-provider.types';
import {
  getBrandedHotels,
  getOtherHotels,
} from '../components/list-view/list-view-utils/list-view-brand-banner.utils';

type GetVisibleInBoundHotelsProps = {
  currentPageVisibleHotels: HotelType[];
  brandCode: string | undefined;
  brandName: string | undefined;
};

export const useGetVisibleInBoundHotels = ({
  currentPageVisibleHotels,
  brandCode,
  brandName,
}: GetVisibleInBoundHotelsProps) => {
  const brandedVisibleHotels = useMemo(
    () => getBrandedHotels(currentPageVisibleHotels, brandCode, brandName),
    [currentPageVisibleHotels, brandCode, brandName]
  );

  const otherVisibleHotels = useMemo(
    () => getOtherHotels(currentPageVisibleHotels, brandCode, brandName),
    [currentPageVisibleHotels, brandCode, brandName]
  );

  return { brandedVisibleHotels, otherVisibleHotels };
};
