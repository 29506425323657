import type { HotelType } from '../../../providers/app-provider/app-provider.types';

export const shouldDisplayBrandedHotels = (brandName: string | undefined): boolean => {
  if (brandName !== 'hilton-honors') {
    return true;
  }
  return false;
};

export const getBrandedHotels = (
  hotelPageCards: HotelType[],
  brandCode: string | undefined,
  brandName: string | undefined
): HotelType[] =>
  shouldDisplayBrandedHotels(brandName)
    ? hotelPageCards.filter((hotel) => hotel.brandCode === brandCode)
    : [];

export const getOtherHotels = (
  hotelPageCards: HotelType[],
  brandCode: string | undefined,
  brandName: string | undefined
): HotelType[] =>
  shouldDisplayBrandedHotels(brandName)
    ? hotelPageCards.filter((hotel) => hotel.brandCode !== brandCode)
    : hotelPageCards;

//link for no branded hotels found info banner that removes brand slug from uri
export const getInfoBannerURLWithBrandRemoved = ({
  baseUrl,
  path,
  locale = 'en',
  canonicalSlug = '',
  altSlugs = [],
  brandCode,
}: {
  baseUrl: string;
  path: string;
  locale?: string;
  canonicalSlug?: string | null;
  altSlugs?: string[];
  brandCode?: string;
}) => {
  //remove brand filter param from URL
  const newURL = path.replace(`&f-brandCodes=${brandCode}`, '');
  //remove slug from URL
  if (canonicalSlug && newURL.includes(canonicalSlug))
    return encodeURI(`${baseUrl}${locale}${newURL.replace(`${canonicalSlug}/`, '')}`);
  const slug = altSlugs?.find((slug) => newURL.includes(slug)) ?? '';
  return encodeURI(`${baseUrl}${locale}${newURL.replace(`${slug}/`, '')}`);
};
