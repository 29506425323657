import { useQuery } from '@tanstack/react-query';
import type { HotelAddress, HotelSummaryOptionsQuery } from '../../gql/types';

const BLOB_URL = 'https://www.test.hilton.com/vmd/map-blob/hotel-summary-blob-v1.json';
const BLOB_KEY = 'blob';
const hardcodedPOCFetch = async () => {
  try {
    const response = await fetch(BLOB_URL);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
    }

    const data: (Exclude<
      HotelSummaryOptionsQuery['hotelSummaryOptions'],
      null | undefined
    >['hotels'][0] & {
      latitude: number;
      longitude: number;
    } & HotelAddress)[] = await response.json();
    return data.reduce((normalizedData, cacheValue) => {
      if (cacheValue?.ctyhocn) {
        cacheValue['localization'] = {
          currencyCode: 'USD',
          coordinate: { latitude: cacheValue.latitude, longitude: cacheValue.longitude },
        };
        cacheValue['address'] = {
          addressLine1: cacheValue.addressLine1,
          city: cacheValue.city,
          country: cacheValue.country,
          state: cacheValue.state,
        };
        normalizedData[cacheValue?.ctyhocn] = cacheValue;
      }
      return normalizedData;
    }, {} as Record<string, Exclude<HotelSummaryOptionsQuery['hotelSummaryOptions'], null | undefined>['hotels'][0]>);
  } catch {
    throw new Error('Failed to fetch data from the server.');
  }
};

export const useBlob = ({ enabled }: { enabled: boolean }) => {
  const { isFetching, status, data } = useQuery({
    queryKey: [BLOB_KEY],
    queryFn: hardcodedPOCFetch,
    enabled,
  });

  return { data, isFetching, isInitialRender: status === 'success' || status === 'error' };
};
