/* eslint-disable react/display-name */
import * as React from 'react';

import type { HotelImageVariant, HotelImageRatio, Maybe } from '../../gql/types';
import getOptimizedImages from '../../utils/get-optimized-images';
import { CarouselSingle } from '@dx-ui/osc-carousel';
import { getAspectRatioUrl } from '@dx-ui/utilities-images';
import { useFeatureToggleConfig } from '../../hooks';
import { useRect } from '@dx-ui/utilities-use-rect';

export type IHotelImagesVariants =
  | {
      altText?: string | null;
      variants?: Maybe<Pick<HotelImageVariant, 'url' | 'size'>[]> | undefined;
      size?: 'xs' | 'sm' | 'md';
      ratios?: Maybe<Pick<HotelImageRatio, 'url' | 'size'>[]> | undefined;
    }
  | undefined;

type ICarousel = {
  images?: IHotelImagesVariants[];
  isLoading?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Carousel = ({ images, isLoading }: ICarousel) => {
  const carouselRef = React.useRef<HTMLDivElement | null>(null);
  const rect = useRect({ ref: carouselRef });
  const { enabled: isImageAspectRatiosToggleEnabled } = useFeatureToggleConfig(
    'search-ui-aspect-ratios-images'
  );

  const carouselImages = images
    ?.map((props) => {
      const { imgUrl } = getAspectRatioUrl({
        src: props?.ratios?.[0]?.url,
        width: rect?.width ?? 0,
      });

      const src =
        isImageAspectRatiosToggleEnabled && props?.ratios?.[0]?.url
          ? imgUrl
          : getOptimizedImages(props?.variants || [], props?.size || 'md');
      return { alt: props?.altText || '', url: src || '' };
    })
    .filter((image) => image.url);

  return carouselImages && carouselImages?.length >= 1 ? (
    <div ref={carouselRef}>
      <CarouselSingle
        data-testid="imageCarousel"
        images={carouselImages || []}
        isLoading={isLoading}
      />
    </div>
  ) : null;
};

export default Carousel;
