import type * as React from 'react';
import type { SortByDropDownValues } from '../../utils';
import { isSortByPoints, isSortByPrice } from '../../utils';
import Dropdown from './dropdown-ui';
import { useMetrics, useGetSortByOptions } from '../../hooks';
import { useTranslation } from 'next-i18next';
import { useAppState, useAppDispatch } from '../../providers/app-provider';

type SortByDropDownProps = {
  isInvalidOrUnavailableOffer?: boolean;
  onChange?: () => void;
  filteredHotelCount?: number;
};

export const SortByDropDown = ({
  filteredHotelCount,
  isInvalidOrUnavailableOffer,
  onChange,
}: SortByDropDownProps) => {
  const { t } = useTranslation('sort-by-drop-down');
  const { sortType, shouldUsePoints } = useAppState();
  const dispatch = useAppDispatch();

  const metrics = useMetrics();

  const { sortByOptions: sortOptions } = useGetSortByOptions({
    filteredHotelsCount: filteredHotelCount,
    isInvalidOrUnavailableOffer,
  });

  const handleUsePointsOnSortChange = (sortBy: SortByDropDownValues) => {
    //When switching to -> Points andshouldUsePointsis not selected, force usePoints select
    if (isSortByPoints(sortBy) && !shouldUsePoints) {
      dispatch({ type: 'SET_USE_POINTS', payload: true });
    }
    //When switching to -> Price  andshouldUsePointsis selected, force usePoints de-select
    if (isSortByPrice(sortBy) && shouldUsePoints) {
      dispatch({ type: 'SET_USE_POINTS', payload: false });
    }
    return;
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: 'SET_SORT_TYPE', payload: event.target.value as SortByDropDownValues });
    dispatch({ type: 'SET_PAGINATION_INDEX', payload: 0 });
    metrics.propertySearchSort(event.target.value);
    if (onChange) {
      onChange();
    }
    handleUsePointsOnSortChange(event.target.value as SortByDropDownValues);
  };

  if (sortType === null || !sortOptions.length) return null;

  return (
    <Dropdown
      handleChange={handleChange}
      label={t('label')}
      options={sortOptions}
      value={sortType}
    />
  );
};
