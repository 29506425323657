import { useRef, useEffect } from 'react';
import { useMap } from './map.context';

export type IMapStaticMarker = {
  icon?: google.maps.MarkerOptions['icon'];
  position: google.maps.MarkerOptions['position'];
  shouldShowCenterMarker?: boolean;
};

const MapStaticMarker = ({ icon = '', position, shouldShowCenterMarker }: IMapStaticMarker) => {
  const { map } = useMap();
  const centerMarker = useRef<google.maps.Marker | null>(null);

  useEffect(() => {
    if (map && position) {
      //if marker already drawn remove it.
      if (centerMarker.current) centerMarker.current.setMap(null);

      //draw new center marker on map
      // eslint-disable-next-line no-new
      centerMarker.current = shouldShowCenterMarker
        ? new google.maps.Marker({
            icon,
            map,
            position,
          })
        : null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [icon, map, JSON.stringify(position)]);

  return null;
};

export default MapStaticMarker;
