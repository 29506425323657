import { keepPreviousData, type UseQueryOptions } from '@tanstack/react-query';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import type { HotelSummaryOptionsQuery } from '../../../gql/operations';
import {
  HotelSummaryOptionsDocument,
  HotelSummaryOptions_VariantDocument,
} from '../../../gql/queries';
import type { HotelQuadrant, HotelSummaryOptions_VariantQuery } from '../../../gql/types';

export const generateHotelSummaryOptionsQueryArray = ({
  language,
  enabled,
  quadNodes,
  isImageAspectRatiosToggleEnabled,
  guestLocationCountry,
}: {
  language: string;
  enabled: boolean;
  quadNodes?: HotelQuadrant[];
  isImageAspectRatiosToggleEnabled?: boolean;
  guestLocationCountry?: string;
}) =>
  quadNodes?.reduce((arr, node) => {
    arr.push({
      queryKey: [
        isImageAspectRatiosToggleEnabled
          ? HotelSummaryOptionsDocument
          : HotelSummaryOptions_VariantDocument,
        {
          language,
          input: {
            quadrantId: node?.id,
            guestLocationCountry,
          },
        },
      ],
      enabled: isBrowser && enabled,
      placeholderData: keepPreviousData,
      retry: 2,
    });
    return arr;
  }, [] as UseQueryOptions<HotelSummaryOptionsQuery | HotelSummaryOptions_VariantQuery>[]) || [];
