import { createContext, useContext } from 'react';
import type MarkerClusterer from '@googlemaps/markerclustererplus';

type IMapContext = {
  apiKey?: string;
  clusterer?: MarkerClusterer;
  infoWindow?: google.maps.InfoWindow;
  infoWindowContainer?: HTMLDivElement;
  map?: google.maps.Map;
  setClusterer: (clusterer?: MarkerClusterer) => void;
  setInfoWindow: (infoWindow?: google.maps.InfoWindow) => void;
  setInfoWindowContainer: (infoWindowContainer?: HTMLDivElement) => void;
  setMap: (map?: google.maps.Map) => void;
};

const defaultContext: IMapContext = {
  apiKey: undefined,
  clusterer: undefined,
  infoWindow: undefined,
  infoWindowContainer: undefined,
  map: undefined,
  setClusterer: () => null,
  setInfoWindow: () => null,
  setInfoWindowContainer: () => null,
  setMap: () => null,
};

export const MapContext = createContext<IMapContext>(defaultContext);

export const useMap = () => {
  const context = useContext(MapContext);
  if (context === undefined) {
    throw new Error('useMap must be used within an MapProvider');
  }
  return context;
};
