/* eslint-disable validate-jsx-nesting/no-invalid-jsx-nesting */
import { Dialog } from '@dx-ui/osc-dialog-v2';
import { useTranslation } from 'next-i18next';
import { useMediaQuery } from 'usehooks-ts';
import { useWrappedRouter } from '../../hooks';
import { getModalSize, MOBILE_COMPARE_LIMIT, TABLET_COMPARE_LIMIT } from './compare-hotel-utils';

import { useGetCompareHotelsInfo } from './hooks/use-get-compare-hotels';
import Image from 'next/image';
import { CompareSkeleton } from './compare-skeleton';
import { TripAdvisorRating } from '../trip-advisor/trip-advisor-rating';
import Icon from '@dx-ui/osc-icon';
import { RateInfoMessages } from '../rate-name-cta/rate-name-cta';
import { Link } from '@dx-ui/osc-link';
import { HotelBadges } from '../hotel-card/hotel-badges';
import type { GoogleCoordinate } from '../../utils';
import { useAppDispatch } from '../../providers/app-provider';

type CompareModalProps = {
  centerCoordinate: GoogleCoordinate | null;
  hotelsToCompare: string[];
  isOpen: boolean;
  onDismiss: () => void;
  onStopCompare?: () => void;
};
export const CompareModal = ({
  hotelsToCompare,
  isOpen,
  onDismiss,
  onStopCompare,
  centerCoordinate,
}: CompareModalProps) => {
  const { t } = useTranslation('compare-hotels');
  const {
    router: { asPath },
  } = useWrappedRouter();
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isMobile = useMediaQuery('(max-width:640px)');
  const isTablet = useMediaQuery(`(max-width:1023px)`);

  const dispatch = useAppDispatch();

  const showRemoveCTA = hotelsToCompare.length > 2;

  const modalSize = getModalSize({
    isMobile,
    isDesktop: isTablet || isDesktop,
    compareCtyhocnsCount: hotelsToCompare?.length || 0,
  });

  const onRemoveHotel = (ctyhocnToRemove?: string) => {
    if (ctyhocnToRemove) {
      const newCtyhocnList = hotelsToCompare?.filter((hotel) => hotel !== ctyhocnToRemove);
      dispatch({ type: 'SET_HOTELS_TO_COMPARE', payload: newCtyhocnList });
    }
  };
  let displayHotelCount = hotelsToCompare?.length;

  if (isMobile) displayHotelCount = MOBILE_COMPARE_LIMIT;
  else if (isTablet)
    displayHotelCount =
      hotelsToCompare.length > TABLET_COMPARE_LIMIT ? TABLET_COMPARE_LIMIT : hotelsToCompare.length;

  const { hotelInfo, isMPALoading, isFetching } = useGetCompareHotelsInfo({
    ctyhocns: hotelsToCompare,
    centerCoordinate,
    displayHotelCount,
    isEnabled: isOpen,
  });

  const handleOnDismiss = (
    event: React.MouseEvent | React.KeyboardEvent,
    isStopCompare?: boolean
  ) => {
    if (!!isStopCompare) {
      event.preventDefault();
      event.stopPropagation();
    }
    onDismiss();
  };

  const handleStopComparing = (event: React.MouseEvent | React.KeyboardEvent) => {
    if (hotelsToCompare.length) dispatch({ type: 'SET_HOTELS_TO_COMPARE', payload: [] });
    if (onStopCompare) setTimeout(() => onStopCompare(), 50);
    handleOnDismiss(event, true);
  };

  const stopComparingButton = () => (
    <div className="bg-bg sticky -bottom-4 flex h-full items-center justify-center py-2">
      <button
        className="btn-primary-link mt-2.5 text-sm"
        type="button"
        onClick={handleStopComparing}
      >
        {t('stopComparing')}
      </button>
    </div>
  );

  return (
    <Dialog
      bottomContent={stopComparingButton()}
      isOpen={isOpen}
      size={modalSize}
      title={t('compare')}
      onDismiss={handleOnDismiss as () => void}
      dialogClassName="max-h-[90vh]"
      stickyHeader
    >
      {isFetching || isMPALoading ? (
        <CompareSkeleton numHotels={hotelsToCompare.length} />
      ) : (
        <div className="flex flex-col overflow-auto">
          <table className="border-border-alt border">
            <tr>
              {hotelInfo.map((data) => {
                return (
                  <td
                    className="border-border-alt h-full border-2 p-0"
                    key={`${data?.ctyhocn}-image`}
                  >
                    <Image
                      alt={data?.masterImage?.altText ?? ''}
                      width={600}
                      height={400}
                      src={data?.masterImage?.url ?? ''}
                      unoptimized={true}
                      data-testid="carousel-image"
                    />
                  </td>
                );
              })}
            </tr>
            <tr>
              {hotelInfo.map((data) => {
                return (
                  <th
                    className="border-border  border-2 p-2 text-left align-top text-sm font-bold"
                    key={data?.name}
                    scope="col"
                  >
                    {data?.name}

                    {data?.badges ? <HotelBadges badges={data?.badges} /> : null}
                    <span
                      className="text-text-alt block whitespace-nowrap text-xs font-normal"
                      data-testid="distanceMarker"
                    >
                      {data?.distance}
                    </span>
                  </th>
                );
              })}
            </tr>
            <tr>
              {hotelInfo.map((data) => {
                const tripAdvisorLocationSummary = data?.tripAdvisorLocationSummary;
                return (
                  <td
                    className="border-border border-2 p-2"
                    key={tripAdvisorLocationSummary?.numReviews}
                  >
                    <TripAdvisorRating
                      tripAdvisorLocationSummary={tripAdvisorLocationSummary}
                      showReviewCount
                      isCompare
                    />
                  </td>
                );
              })}
            </tr>
            <tr>
              {hotelInfo.map((data) => {
                return (
                  <td className="border-border border-2 p-2" key={`${data?.ctyhocn}-rate`}>
                    <RateInfoMessages rateMessages={data?.messages} isCompare />
                  </td>
                );
              })}
            </tr>
            <tr>
              {hotelInfo.map((data) => {
                return (
                  <td
                    className="border-border items-center gap-0.5 border-2 p-2 text-sm"
                    key={`${data?.ctyhocn}-parking`}
                  >
                    <div className="flex items-start sm:items-center">
                      <Icon name="parking" />
                      <span>{data?.parkingType}</span>
                    </div>
                  </td>
                );
              })}
            </tr>
            <tr>
              {hotelInfo.map((data) => {
                return (
                  <td
                    className="border-border items-center gap-0.5 border-2 p-2 text-sm"
                    key={`${data?.ctyhocn}-amenities`}
                  >
                    <ul className="grid grid-cols-1 text-sm">
                      {data?.amenities?.map((amenity) => (
                        <li key={amenity.id} className="flex items-center gap-0.5">
                          <Icon name="check" />
                          <span> {amenity.name}</span>
                        </li>
                      ))}
                    </ul>
                  </td>
                );
              })}
            </tr>
            <tr>
              {hotelInfo.map((data) => (
                <td
                  className="border-border items-center gap-0.5 border-2 p-2 text-sm"
                  key={`${data?.ctyhocn}-rooms`}
                >
                  <ul className="grid grid-cols-1 gap-1.5 text-sm font-bold">
                    <p>
                      {t(
                        asPath.includes('/locations/') ? 'roomTypesOffered' : 'roomTypesAvailable'
                      )}
                    </p>
                    {data?.rooms?.map((room) => (
                      <li
                        key={room?.type}
                        className="flex items-start gap-0.5 font-normal sm:items-center"
                      >
                        <Icon name={room.icon} />
                        <span> {room.type}</span>
                      </li>
                    ))}
                  </ul>
                </td>
              ))}
            </tr>
            <tr>
              {hotelInfo.map((data) => {
                const ctaHref = data?.ctaHref;
                const ctaLabel = data?.ctaLabel;
                return (
                  <td
                    className="border-border items-center gap-0.5 border-2 p-2 text-sm"
                    key={ctaHref}
                  >
                    <div className="flex size-full flex-col justify-end gap-3 p-2 font-bold">
                      {ctaHref && ctaLabel ? (
                        <Link
                          url={ctaHref}
                          className="btn-primary btn flex w-full justify-center no-underline"
                          target="_blank"
                          showNewWindowIcon={false}
                        >
                          {ctaLabel.text}
                        </Link>
                      ) : null}
                      {showRemoveCTA ? (
                        <button
                          className="btn-primary-link hidden text-sm lg:block"
                          type="button"
                          onClick={() => onRemoveHotel(data?.ctyhocn)}
                        >
                          {t('remove')}
                        </button>
                      ) : null}
                    </div>
                  </td>
                );
              })}
            </tr>
          </table>
        </div>
      )}
    </Dialog>
  );
};
