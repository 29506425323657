import { Accordion } from '@dx-ui/osc-accordion';
import { useTranslation } from 'next-i18next';
import { WhyProperty } from './why-property';
import { GOALS, WHY_PROPERTY } from '../../../constants';
import { useGetMVTSelections } from '../../../hooks';
import { useRef } from 'react';
import { sendReward } from '@dx-ui/framework-conductrics';

export const WhyPropertyAccordion = ({ ctyhocn }: { ctyhocn: string }) => {
  const { t } = useTranslation('hotel-card');
  const whyPropertyAccordionRef = useRef(false);
  const { isMVTVariant } = useGetMVTSelections({
    agentId: WHY_PROPERTY.agentId,
    MVTVariants: ['b', 'c'],
  });
  const handleAccordionClick = () => {
    if (!whyPropertyAccordionRef.current) {
      sendReward(GOALS.whyBrandClicked);
      sendReward(GOALS.whyBrandTotalClicks);
      whyPropertyAccordionRef.current = true;
    }
  };
  const isWhyPropertyMvt = isMVTVariant.b || isMVTVariant.c;
  return isWhyPropertyMvt ? (
    <div className="mt-2 w-full">
      <Accordion
        className="accordion-single text-sm"
        items={[
          {
            content: <WhyProperty ctyhocn={ctyhocn} variants={isMVTVariant} />,
            key: 'whyProperty-accordion',
            collapsedButtonLabel: t('whyStayHere'),
            expandedButtonLabel: t('whyStayHere'),
          },
        ]}
        onValueChange={handleAccordionClick}
        type="multiple"
      />
    </div>
  ) : null;
};
