import { useIsClient } from 'usehooks-ts';
import { useHotelQuadrantsQuery } from '../../gql/queries';
import { generateHotelSummaryOptionsQueryArray } from './utils/generate-hotel-summary-options-query-array';
import { useQueries } from '@tanstack/react-query';
import getBoundNodes from '../../utils/get-bounds-nodes/get-bounds-nodes';
import { toNestedBounds } from '../../providers/app-provider/app-provider.utils';
import { useFeatureToggleConfig } from '../use-feature-toggle';
import { useLocation } from '@dx-ui/framework-location-provider';

export const useQuadNodes = ({
  language,
  enabled,
  bounds,
  brandCode,
  stateOrCountryOrRegionFilter,
}: {
  language: string;
  enabled: boolean;
  bounds: google.maps.LatLngBoundsLiteral;
  brandCode?: string;
  stateOrCountryOrRegionFilter:
    | {
        countries:
          | {
              countryCode: string;
              stateCode: string;
            }[]
          | null;
      }
    | undefined;
}) => {
  const isClient = useIsClient();

  const { enabled: isImageAspectRatiosToggleEnabled } = useFeatureToggleConfig(
    'search-ui-aspect-ratios-images'
  );
  const { country: guestLocationCountry, isLoading: isGuestLocationLoading } = useLocation();

  const { data: quadNodesData, isLoading: isQuadNodesDataLoading } = useHotelQuadrantsQuery(
    {},
    {
      enabled: isClient && enabled,
    }
  );
  const quadNodes = getBoundNodes(quadNodesData?.hotelQuadrants || [], toNestedBounds(bounds), {
    ...stateOrCountryOrRegionFilter,
    brandCode,
  });

  const queryArr = generateHotelSummaryOptionsQueryArray({
    language,
    enabled: Boolean(quadNodesData) && !isQuadNodesDataLoading && !isGuestLocationLoading,
    quadNodes,
    isImageAspectRatiosToggleEnabled,
    guestLocationCountry,
  });
  //parallel fetch zoned hotel summary options
  const results = useQueries({ queries: queryArr });

  return {
    isFetching: results.some((result) => result.isFetching),
    isInitialRender: results.some(
      (result) => result.status === 'success' || result.status === 'error'
    ),
  };
};
