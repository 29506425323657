import type * as React from 'react';
import { useState } from 'react';
import { MapContext } from './map.context';
import type MarkerClusterer from '@googlemaps/markerclustererplus';

type MapProviderProps = { apiKey: string; children: React.ReactNode };

const MapProvider = ({ apiKey, children }: MapProviderProps) => {
  const [clusterer, setClusterer] = useState<MarkerClusterer>();
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();
  const [infoWindowContainer, setInfoWindowContainer] = useState<HTMLDivElement>();
  const [map, setMap] = useState<google.maps.Map>();

  return (
    <MapContext.Provider
      value={{
        apiKey,
        clusterer,
        infoWindow,
        infoWindowContainer,
        map,
        setClusterer,
        setInfoWindow,
        setInfoWindowContainer,
        setMap,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export default MapProvider;
